<template>
  <div data-v-4d6acc36="" data-v-398c5ea6="" class="house-info">
    <div data-v-4d6acc36="" class="content relative">
      <div data-v-4d6acc36="" class="flex">
        <div data-v-4d6acc36="" class="img relative">
          <div data-v-4d6acc36="" class="subtitle">聯碩地產行銷團隊</div>
          <a data-v-4d6acc36="" href="http://unigiants.com.tw/" target="_blank"
            ><img
              data-v-4d6acc36=""
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMMAAAAwCAMAAACbpz4vAAAAYFBMVEX////PAA729vbPAA729vbPAA729vbPAA729vbPAA729vbPAA729vbPAA729vbPAA729vbPAA729vbPAA729vbPAA729vbPAA729vbPAA729vbPAA729vbPAA729vbPAA6yfxSlAAAAHnRSTlMAEBEgIjAzQERQVWBmcHeAiJCZoKqwu8DM0N3g7vBKt+guAAADjElEQVR4Xu3VUXcbJxCG4QmdricbMqaEjgnBX/7/v2xYwUokOrZa68I53fdyLiSeIwbRnw+3RO+6x+839IXedQ+3GB5+J8NhOAyH4TAIt8FCNyW2Xp8HmluEruU898G6vt0QTxHV1AZVp3FU25uOhkLXShCaStWRi3s6DEjdgPJ2A06Rx0JECjeNkUopFbX8iFrWA7JtJZptE5UcAhFjz6gXwfczECJ5CNcodeGqPnVFgHSmwk/kqUJTOU+GACfM0EWJWKnYGevvbVitSs7OquxfY9gwi4jBi8iJBqOpboj4OWoxAtXCUIaQ1mFIZlbM7H6GEg0rACwMANzntVCroCfdsOpFw6DWAmxvbINiZShZIkvDUC6c2CpvNLRkWREWEkHwtMWIJ0OWVuwGWQwXkSy051BpSpFjNWIEiSYI0g3EvD9IJ3e6bvj29d/sgyvGYMrZ1RPCI2/vSMncOm+HgUcZdBmj0JShAkyM4R6GmohKeWUfvn76QEQf/77V4DM0ICSkVHM/asumu9QNNDK8+CgRuwWqnhFEionIMFgmqvay4ZF6H59vMyhQc8lAsbRthNsOXYxK8a10YdBRmQ0r9i6eqVQYSqRgomEIIEJ40fCZpvkt+7Cuzmn0nohDM+huMGrpheHcbFAU29rPFbAsASvULCNbGAaPhbFS2N+BXwxPdNHjq4asCeIhAq9gvx2Wazdcu0t8bjLYcNY8CFsGzY13NghWAZNhNAzXj/rhln24YrBhKNqy6wZHF1U4mv5EtCQVjgkq8G0wDAz19bxP5RfDM009/RcDib/pLpVpHdI4YRpb7hbWwL8YHEKwlwxPNPX4msHUmgFohv3S7AaUVr220zoZ8mAKwjiqFtXKUJ7vEgWxbHYnA1e0ZIkxY1Ek2w0to5BgMdYU+cqDemlQ5D718AMjFhkKpTX+yHeDaKpAjXcykAW/nHbAKimYvesG2/7jnCGQoDC9bFiBpU8DZGBcNCoG7bpuqEAOcse7NO6NQpthmhH5uh1AapXdUPbOBgXWBmAiV8Zup0Ihk+rPBr+6ebXuY2BDokvD9nu4jCL9ztVlGGxvN1gnOLTiOHIkWYn4ZHCl7Ms+f9Q9DGtZnGnDFEdTq47Bon2kkfbU96FYF6qZKY0ctZxtPooW548PRlshvsHwbvrNDIfhMByGw/DX2fCJ3nVfvp/7YzI8nw3f6P/a0dHR0dHR0dE/ulHrIVa8hgIAAAAASUVORK5CYII="
              alt=""
              class="banner"/></a
          ><a
            data-v-4d6acc36=""
            href="https://www.facebook.com/unigiant/"
            target="_blank"
            ><img
              data-v-4d6acc36=""
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANkAAAAvCAMAAABZq59AAAAAXVBMVEX////29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vb29vZR/o/aAAAAHnRSTlMAEBEgIjAzQERQVWBmcHeAiJCZoKqwu8DM0N3g7vBKt+guAAAEzElEQVR4Xu3WYVPrKBTG8aeIyI1cmkU8IuLz/T/m3pOwMcmk7c7uKzv+XsSR1jT/4dARO49P6gH35fT7ld3nyzPuxsMLNz7PJ9yFM/f4+eseNuydR15O+OYeP3ns/XQHYfeYdvrgZa+4zlu9OtwyeHQxwVqsherw78gIlUas+bS699rr/kvxUXOf+tH7jSNpBrQMAC325S7KAjMKOiFK85tqCraGuDYAm7vY3fsjx697r/y6MH9vPfQBBzhDoAMQaTbLzLXWxlb/OCozu7RCjw3hmuzKMktSo1mC3FHZdhY/TtiW8QVHmBDobUu+OdtiyL1tXB4yMqDrZT6oyhAi0xTQVVaZ5V5G/2Vf5tnNPcbaoTjrtbUxKQv1zI1n7Mv4cLFskOZLMdJ8FcyEU6LzXhi892ZVJlyxALjHumxCUmqZPRFhEzGVaZ7hDFU5Sfzie8AGJg/n8/mD3RkHWJNwIEln+4NOWoWqmw9x0zNFYVCsesVKJbaEwzTP9Q9H2Yy6sNIDrjULNYSJ2U/jiRtvB0fvHQeovBs4OnjPMWBimaBq8SrNZZ4qCaFagbpeNs+z3R7RwGpsy6Y2b9sy9kE57Muej8reufFw8ZyZKpYWpZgWoMJ0uCNqsWqk30yjrqFWIJlbZSGEzBjCsCpLZIIHbGuNA2aWKu3L8NdRGbd+XSoLhXHkmJlz3wihElR22zKF0uDYy7iFTmjZ1VVZ6ze0QhaLziqD/WF9u13G86WySLZSC1kl0wIwU0oV1BpU3pXFWAghYsGMTf4gRW3Lou64LRVVMPOklGrsSEoik4MySQXIHxSV/1+Z8sNgTEwhAHbUsriUCVTclQGJSLRlXC0DlbuhlGYZEGkhFbVglirFU8g6AL6QZdCyqsb548RA/a+yEjN9oPcMkTbMU9KWMnYHZZGR7npZreuyiolpIwWFJWDihX7/nwurOSjD1u1pPCiTZRqjkk1ZqYBUDGTB9TKKZZu+9VlRKpSGUuAcu75BEBURrRAD4zjeKHv8D2Xw4eI0ljG3MpU5crheZpn09BTmlEdIg7JN5rezpQkFE1YRJssgBCwy3fWyXzfKJIqWkVq2jN1SxqpaX7aRZGH2vgkMK5y5VjYw9E3yHJAJMw2j62V6Wf+MANNAr2XwlhnqfFC2f+EJe7ZReZdSoYvMspQpwZgpKbWc7PykZHIDYyUDhBWh7cq8iLBhluh6GaSZkaZomsO1skQzlTEJzW5rsPHCxQl7kDG4eQKlIdLaYKCCBqUIIxzhWS2UKfpypnXeGWGljXVXZti/6lSrNo4pV6ox0NNDXSnLVaBllmPfcdz+bnzHsaksMmrZZg0IjRGAb82jg2EBYAuzZcqCiTW9bC1w9CTZquRUmmeiXZd1S5nK2UOYk3Awo4V6uVn2fLnMCjPWZdPemcLq+9Q2hy5zAFxjAhI5YrEvM7UZ422PGejrMqbH3yASY/QAfFty1dOtss8Tjg3VGYmaWA02hmgwc/FrMet1HACYlA1WQsTlXy18Dcsh6BeVIiZadejtRtkZ39Xj9bKPE76t89WyJ3xjb1fKzvjOTu8Xy16A+0jrZfcSpv46Kvt8xh14+tiX8e0B9+H5fVP2+oT78Xh+e4X6eP39H/brx48fP378+PHjx9+CIehtSE9gogAAAABJRU5ErkJggg=="
              alt=""
              class="banner"/></a
          ><img data-v-4d6acc36="" src="texture/logo.png" alt="" class="logo" />
        </div>
        <div data-v-4d6acc36="" class="info">
          <div data-v-4d6acc36="" class="title">建案資訊</div>
          <div data-v-4d6acc36="" class="item">
            <h3 data-v-4d6acc36="" class="label">投資興建</h3>
            <p data-v-4d6acc36="" class="desc">勝聯開發股份有限公司</p>
          </div>
          <div data-v-4d6acc36="" class="item">
            <h3 data-v-4d6acc36="" class="label">營建施工</h3>
            <p data-v-4d6acc36="" class="desc">大陸工程股份有限公司</p>
          </div>
          <div data-v-4d6acc36="" class="item">
            <h3 data-v-4d6acc36="" class="label">建築規劃</h3>
            <p data-v-4d6acc36="" class="desc">曾聰憲建築師事務所</p>
          </div>
          <div data-v-4d6acc36="" class="item">
            <h3 data-v-4d6acc36="" class="label">立面公設</h3>
            <p data-v-4d6acc36="" class="desc">如榆建築及設計事務所</p>
          </div>
          <!-- <div data-v-4d6acc36="" class="item">
            <h3 data-v-4d6acc36="" class="label">營建施工</h3>
            <p data-v-4d6acc36="" class="desc"></p>
          </div> -->
          <div data-v-4d6acc36="" class="item">
            <h3 data-v-4d6acc36="" class="label">書苑合作</h3>
            <p data-v-4d6acc36="" class="desc">臺灣蔦屋股份有限公司</p>
          </div>
          <div data-v-4d6acc36="" class="item">
            <h3 data-v-4d6acc36="" class="label">建築代銷</h3>
            <p data-v-4d6acc36="" class="desc">聯碩地產股份有限公司</p>
          </div>
          <div data-v-4d6acc36="" class="item">
            <h3 data-v-4d6acc36="" class="label">建案位置</h3>
            <p data-v-4d6acc36="" class="desc">
              台南市北區 西門路四段/文成三路口
            </p>
          </div>
          <div data-v-4d6acc36="" class="item">
            <h3 data-v-4d6acc36="" class="label">建照號碼</h3>
            <p data-v-4d6acc36="" class="desc">(109)南工造字第03422號</p>
          </div>
          <div data-v-4d6acc36="" class="item">
            <h3 data-v-4d6acc36="" class="label">經紀人</h3>
            <p data-v-4d6acc36="" class="desc">林建汶 107年新北經字003583號</p>
          </div>
          <div data-v-4d6acc36="" class="item">【西門大院－全案彰化銀行信託管理安心保障】</div>
        </div>
      </div>
      <img
        data-v-4d6acc37=""
        src="../pages/texture/logo.png"
        alt=""
        class="logo"
        v-if="!isMobile"
      />
      <img
        data-v-4d6acc37=""
        src="../pages/mobile-texture/logo.png"
        alt=""
        class="logo"
        v-if="isMobile"
      />
    </div>
    <Footer />
  </div>
</template>

<script>
import info from "@/info";
import { isMobile } from "@/utils";
import Footer from "@/layouts/Footer.vue";
export default {
  name: "houseInfo",
  components: {
    Footer
  },
  data() {
    return {
      isMobile,
      houseInfos: info.houseInfos
    };
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
// @import '@/assets/style/variableColor.scss';
// .house-info {
//   width: 100vw;
//   background: $house_bg;
//   position: relative;
//   z-index: 1;
// }
// .content {
//   width: 686px;
//   margin: 0 auto;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-direction: column;
// }

// .title {
//   font-size: 36px;
//   line-height: 36px;
//   margin: 60px 0;
//   font-family: 'Noto Serif TC', serif;
//   font-weight: bold;
//   color: $house_title_color;
// }

// .info {
//   display: flex;
//   flex-wrap: wrap;
// }

// .item {
//   font-size: 16px;
//   margin-bottom: 40px;
//   margin-right: 160px;
//   width: 260px;
//   height: 30px;
//   border-left: 4px solid $house_label_color;
//   padding-left: 12px;
//   display: flex;
//   align-items: center;
//   white-space: nowrap;

//   &:nth-of-type(even) {
//     margin-right: 0;
//   }

//   &:nth-last-child(1) {
//     height: auto;
//     border-left: none;
//     align-items: flex-start;

//     .label {
//       height: 30px;
//       border-left: 4px solid $house_label_color;
//       padding-left: 12px;
//       padding-top: 6px;
//       margin-left: -12px;
//     }
//   }

//   .label {
//     font-size: 16px;
//     color: $house_label_color;
//     margin-bottom: 0;
//     margin-right: 20px;
//     white-space: nowrap;
//   }

//   .desc {
//     font-size: 15px;
//     text-align: left;
//     line-height: 1.4;
//     color: $house_desc_color;
//   }
// }

// /* 螢幕尺寸標準 */
// /* 手機尺寸 */
// @media only screen and (max-width: 767px) {
//   .content {
//     width: 100%;
//     margin-bottom: 20px;
//   }

//   .title {
//     font-size: 32px;
//     margin: 40px 0 24px;
//   }

//   .item {
//     margin-bottom: 20px;
//     margin-right: 0;
//     width: 100%;
//     height: auto;
//     margin-left: 20px;
//     white-space: normal;
//   }

//   .info {
//     width: 100%;
//   }
// }

// /* 平板尺寸 */
// @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
//   .content {
//     width: 686px;
//   }

//   .title {
//     font-size: 32px;
//     margin: 40px 0 24px;
//   }

//   .item {
//     margin-bottom: 20px;
//   }
// }

// // 避免內容電腦過渡平板時，設計未考量的調整
// @media only screen and (min-width: 1025px) and (max-width: 1199.98px) {
// }
</style>
