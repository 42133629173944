var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new-section"},_vm._l((_vm.features),function(feature,i){return _c('div',{key:i,staticClass:"feature-box",class:{ 'flex-reverse': feature.flex_reverse == true }},[(_vm.isMobile)?_c('div',{staticClass:"feature-info"},[_c('div',{staticClass:"f_tip",class:{ 'flex-reverse': feature.flex_reverse == true },domProps:{"innerHTML":_vm._s(feature.f_tip)}}),_c('div',{staticClass:"f_st",domProps:{"innerHTML":_vm._s(feature.f_st)}}),(feature.f_t == 'SPACE AESTHETICS')?_c('div',{staticClass:"feature-buttons"},[_c('div',{class:{ active: _vm.activeP == '26p' },on:{"click":function($event){return _vm.changeP('26p')}}},[_c('span',[_vm._v("26坪")]),_vm._v("／"),_c('span',[_vm._v("2房")])]),_c('div',{class:{ active: _vm.activeP == '36p' },on:{"click":function($event){return _vm.changeP('36p')}}},[_c('span',[_vm._v("36坪")]),_vm._v("／"),_c('span',[_vm._v("3房")])]),_c('div',{class:{ active: _vm.activeP == '52p' },on:{"click":function($event){return _vm.changeP('52p')}}},[_c('span',[_vm._v("52坪")]),_vm._v("／"),_c('span',[_vm._v("4房")])])]):_vm._e(),_c('div',{staticClass:"f_des",domProps:{"innerHTML":_vm._s(feature.f_des)}})]):_vm._e(),_c('div',{class:("swiper-box sw-" + (i + 1))},[(_vm.swiperReset)?_c('swiper',{ref:("swiper-" + (i + 1)),refInFor:true,attrs:{"options":feature.swiperOptions}},[_vm._l((feature.f_slides[0].img),function(img,i){return _c('swiper-slide',{key:i,style:({
            backgroundImage: ("url(" + img + ")"),
            paddingTop: ((feature.slideRatio) + "%"),
          })})}),(!_vm.isMobile)?_c('div',{class:feature.flex_reverse
              ? [
                  'swiper-pagination',
                  'flex-reverse',
                  ("swiper-pagination-" + (i + 1)) ]
              : ['swiper-pagination', ("swiper-pagination-" + (i + 1))],attrs:{"slot":"pagination"},slot:"pagination"}):_vm._e()],2):_vm._e(),(feature.f_t == 'SPACE AESTHETICS' & !_vm.isMobile)?_c('div',{staticClass:"feature-buttons"},[_c('div',{class:{ active: _vm.activeP == '26p' },on:{"click":function($event){return _vm.changeP('26p')}}},[_c('span',[_vm._v("26坪")]),_vm._v("／"),_c('span',[_vm._v("2房")])]),_c('div',{class:{ active: _vm.activeP == '36p' },on:{"click":function($event){return _vm.changeP('36p')}}},[_c('span',[_vm._v("36坪")]),_vm._v("／"),_c('span',[_vm._v("3房")])]),_c('div',{class:{ active: _vm.activeP == '52p' },on:{"click":function($event){return _vm.changeP('52p')}}},[_c('span',[_vm._v("52坪")]),_vm._v("／"),_c('span',[_vm._v("4房")])])]):_vm._e()],1),(_vm.isMobile)?_c('div',{staticClass:"f_t",domProps:{"innerHTML":_vm._s(feature.f_t)}}):_vm._e(),(!_vm.isMobile)?_c('div',{staticClass:"feature-info",class:{ 'flex-reverse': feature.flex_reverse == true }},[_c('div',{staticClass:"f_t",domProps:{"innerHTML":_vm._s(feature.f_t)}}),_c('div',{staticClass:"f_st",domProps:{"innerHTML":_vm._s(feature.f_st)}}),_c('div',{staticClass:"f_des",domProps:{"innerHTML":_vm._s(feature.f_des)}}),(!_vm.isMobile)?_c('div',{staticClass:"f_tip",class:{ 'flex-reverse': feature.flex_reverse == true },domProps:{"innerHTML":_vm._s(feature.f_tip)}}):_vm._e()]):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }