// import { isMobile } from '@/utils'

export default {
  address:'台南市北區西門路四段 | 文成三路口',
  //address:  isMobile ? '接待會館：<br>台南市北區西門路四段/文成三路口':'接待會館：台南市北區西門路四段/文成三路口',
  // isMobile ? '接待中心：<br />106台北市大安區基隆路三段20-1號' : '接待中心：106台北市大安區基隆路三段20-1號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1836.11190450433!2d120.20896865861967!3d23.015553233411012!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjPCsDAwJzU2LjAiTiAxMjDCsDEyJzM2LjIiRQ!5e0!3m2!1szh-TW!2stw!4v1611810113323!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/z6M9roU1sno2LdKr9',
  phone: '06 225 6688',
  fbLink:
    'https://www.facebook.com/%E8%A5%BF%E9%96%80%E5%A4%A7%E9%99%A2-101474465251897/',
  fbMessage: 'https://m.me/101474465251897',
  caseName: '西門大院',
  houseInfos: [
    ['投資興建', '築億建設股份有限公司'],
    ['建築設計', '林永發建築師事務所'],
    ['工程營造', '興澤營造'],
    ['基地面積', '586.43坪'],
    ['公設比例', '34%'],
    ['樓層規劃', '15層/B4層'],
    // ['公設比例', '32.5%'],
    // ['樓層規劃', '地上15層，地下6層'],
    // ['基地面積', '846.66坪'],
    // ['建照號碼', '102股建字第00258號等1個'],
  ],
  gtmCode: ['NB5CH3D','TVLHSXN'], // 可放置多個
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '預約賞屋',
    subTitle: ''
  },
}
